import request from "@/lib/request";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import {IContact, IItem} from "@/layouts/workspace/growth/company_follow_up/typings";

export async function getStatus(): Promise<IItem[]> {
    const rs = await request.request<unknown, AxiosResponse, unknown>(({
        url: "growth/company/follow/up/status",
        method: 'get',
    } as AxiosRequestConfig));
    return rs.data;
}

export async function getContacts(): Promise<IContact[]> {
    const rs = await request.request<unknown, AxiosResponse, unknown>(({
        url: "growth/company/follow/up/contacts",
        method: 'get',
    } as AxiosRequestConfig));
    return rs.data;
}


export async function getContact(): Promise<IContact> {
    const rs = await request.request<unknown, AxiosResponse, unknown>(({
        url: "growth/company/follow/up/contact",
        method: 'get',
    } as AxiosRequestConfig));
    return rs.data;
}


export async function putContact(c: IContact): Promise<IContact> {
    const rs = await request.request<unknown, AxiosResponse, unknown>(({
        url: "growth/company/follow/up/contact",
        method: 'put',
        data: c
    } as AxiosRequestConfig));
    return rs.data;
}
